import { Layout, SEO } from 'components';
import { Link } from 'gatsby';
import * as React from 'react';
import shieldImage from './../images/security-features/shield.svg';
import secureCloudImage from './../images/security-features/secure-cloud.svg';
import dataPermImage from './../images/security-features/data-perm.svg';
import encryptionImage from './../images/security-features/encryption.svg';
import warningImage from './../images/security-features/warning.svg';
import twofaImage from './../images/security-features/2fa.svg';
import permissionsImage from './../images/security-features/permissions.svg';
import euGdprImage from './../images/security-features/eu-gdpr.svg';
import shieldOneImage from './../images/security-features/shield-1.svg';
import lockImage from './../images/security-features/lock.svg';
import californiaImage from './../images/security-features/california.svg';
import pciImage from './../images/security-features/pci.svg';
import safebaseImage from './../images/security-features/safebase.svg';
import trainingImage from './../images/security-features/training.svg';
import policyImage from './../images/security-features/policy.svg';
import confidentImage from './../images/security-features/confident.svg';
import questionImage from './../images/security-features/question.svg';
import searchImage from './../images/security-features/search.svg';

interface ISecurityFeaturesProps {
}

const SecurityFeatures: React.FunctionComponent<ISecurityFeaturesProps> = () => {

  return (
    <Layout>
      <SEO title="Data Privacy & Security Features"
        description='Security of your data and your privacy is a business priority at Teamble. We build everything with customer trust and security in mind.' />
      <div className="security" id="security">
        <div className="d-flex flex-wrap mt-64">
          <div className="container flex-grow-1">
            <img
              className="icon-header"
              src={shieldImage}
              alt="Security Shield"
            />
          </div>
          <div className="flex-grow-1">
            <div className="container">
              <div className="header">
                Data Security and Privacy
              </div>
              <div className="main-text">
                Security of your data and your privacy is a business priority at
                Teamble. We build everything with customer trust and security in
                mind. For us, security is not a set of guidelines to meet but it is
                one of the most critical service features we provide. Here are our
                Data Security and Privacy Features:
              </div>
            </div>
            <div className="container">
              <div className="sub-header">
                Key Security Features
              </div>
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div>
                    <img
                      className="icon"
                      src={secureCloudImage}
                      alt=""
                    />
                    <div className="title">
                      Secure Cloud Infrastructure
                    </div>
                    <div className="section-text">
                      Teamble’s data and services are hosted with world’s leading
                      cloud provider Amazon Web Services (AWS) in its US facilities,
                      spread across multiple availability zones to ensure
                      reliability and disaster recovery. AWS data centers are
                      monitored by 24X7 security, biometric scanning, video
                      surveillance and are SOC 1, SOC 2, and SOC 3 certified.
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-64 mt-lg-0 col-lg-6">
                  <div>
                    <img
                      className="icon"
                      src={dataPermImage}
                      alt=""
                    />
                    <div className="title">
                      Data Permissions and Authentication
                    </div>
                    <div className="section-text">
                      Access to customer data is limited to authorized employees
                      whose job functions require it. Additionally, 2FA and strong
                      password policies on all tools used internally are strictly
                      implemented for all Teamble employees to ensure third-party
                      access to these cloud services are protected.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container margin-top-bg">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div>
                    <img
                      className="icon"
                      src={encryptionImage}
                      alt=""
                    />
                    <div className="title">
                      Encryption
                    </div>
                    <div className="section-text">
                      All data is transmitted over HTTPS, and any data stored is
                      encrypted in transit and at rest using 256-bit encryption. Our
                      application endpoints are TLS/SSL only and score an “A” rating
                      on Qualys SSL Labs‘ tests. This means we only use strong
                      cipher suites and have features such as HSTS and Perfect
                      Forward Secrecy fully enabled.
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-64 mt-lg-0  col-lg-6">
                  <div>
                    <img
                      className="icon"
                      src={warningImage}
                      alt=""
                    />
                    <div className="title">
                      Incident Response
                    </div>
                    <div className="section-text">
                      Teamble has a protocol in place for handling various security
                      incidences, all of which employees are informed and trained
                      on. Breaches will be communicated within a reasonable amount
                      of time, and vulnerabilities are fixed ASAP.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container margin-top-bg">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div>
                    <img
                      className="icon"
                      src={twofaImage}
                      alt=""
                    />
                    <div className="title">
                      SSO and 2FA
                    </div>
                    <div className="section-text">
                      Teamble inherits the same authentication method that you use
                      for your Slack workspace, including Single Sign-On (SSO) and
                      Two Factor Authentication (2FA). These methods allows you to
                      authenticate users in your own systems without requiring them
                      to enter additional login credentials
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-64 mt-lg-0  col-lg-6">
                  <div>
                    <img
                      className="icon"
                      src={permissionsImage}
                      alt=""
                    />
                    <div className="title">
                      Permissions
                    </div>
                    <div className="section-text">
                      Teamble has different levels of user permissions and settings
                      within the platform for your organization and teams.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container margin-top-bg privacy-shield GDPR">
              <div className="sub-header">
                Enterprise Ready Compliance
              </div>
              <div className="row">
                <div className="col-12 col-lg-4">
                  <div>
                    <img
                      className="icon-small"
                      src={euGdprImage}
                      alt=""
                    />
                    <div className="title">
                      EU GDPR
                    </div>
                    <div className="section-text">
                      As of April 2nd, 2020 Teamble is GDPR compliant. Organizations
                      who employ EU-based individuals can rest assured that Teamble
                      is handling their personal information in compliance with the
                      latest EU laws
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 mt-64 mt-lg-0">
                  <div>
                    <div className="svg-container">
                      <img
                        className="shield"
                        src={shieldOneImage}
                        alt=""
                      />
                      <img
                        className="lock"
                        src={lockImage}
                        alt=""
                      />
                      <img
                        className="california"
                        src={californiaImage}
                        alt=""
                      />
                    </div>
                    <div className="title">
                      CCPA
                    </div>
                    <div className="section-text">
                      As of January 1st, 2020 Teamble is CCPA compliant in how it
                      handles data of California residents.
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 mt-64 mt-lg-0">
                  <div>
                    <img
                      className="icon-small"
                      src={pciImage}
                      alt=""
                    />
                    <div className="title">
                      PCI
                    </div>
                    <div className="section-text">
                      All payments to Teamble are processed through our partner,
                      Stripe. To learn more about their security setup and PCI
                      compliance, you can visit Stripe’s security page.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container margin-top-bg privacy-shield GDPR">
              <div className="md-layout md-gutter">
                <div className="md-layout-item">
                  <div>
                    <div className="section-text">
                      IT Security and Privacy teams, please find details on our security policies,
                      internal practices and certifications all in one dashboard powered by SafeBase:
                    </div>
                    <a href="https://security.teamble.com" target="_blank">
                      <img
                        className="icon-header"
                        src={safebaseImage}
                        alt="safebase"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="container margin-top-sm">
              <div className="section-text">
                Please visit our
                <Link to="/privacy-policy" target="_blank" className='text-decoration-none'><b> Privacy Policy </b></Link>
                to read more about our commitment to the regional and international
                data privacy regulations.
              </div>
            </div>
            <div className="container margin-top-bg">
              <div className="sub-header">
                Commitment to Security
              </div>
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div>
                    <img
                      className="icon"
                      src={shieldImage}
                      alt=""
                    />
                    <div className="title">
                      Security Team
                    </div>
                    <div className="section-text">
                      Security is a company-wide endeavor. Teamble employs staff
                      responsible for reviewing, updating, testing and maintaining
                      our security and privacy policy. Teamble has a dedicated Data
                      Protection Officer (DPO) to handle a variety of issues in
                      accordance with the GDPR, CCPA and any other data privacy and
                      security framework requirements. Having a DPO ensures that
                      Teamble will continue to invest in data security and privacy.
                      To contact our DPO: DPO@teamble.com
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6 mt-64 mt-lg-0">
                  <div>
                    <img
                      className="icon"
                      src={trainingImage}
                      alt=""
                    />
                    <div className="title">
                      Trainings
                    </div>
                    <div className="section-text">
                      Teamble have been diligent in conducting more awareness and
                      training with employees around data protection and incident
                      response on potential issues like data breaches. All employees
                      complete an annual security training program and employ best
                      practices when handling customer data.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container margin-top-bg">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div>
                    <img
                      className="icon"
                      src={policyImage}
                      alt=""
                    />
                    <div className="title">
                      Internal Policies
                    </div>
                    <div className="section-text">
                      Teamble has a set of comprehensive security and awareness
                      policies that cover a wide range of topics. These policies are
                      updated as necessary and shared with all employees.
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6 mt-64 mt-lg-0">
                  <div>
                    <img
                      className="icon"
                      src={confidentImage}
                      alt=""
                    />
                    <div className="title">
                      Confidentiality
                    </div>
                    <div className="section-text">
                      All employee and contractor contracts include a
                      confidentiality agreement contingent on acceptance of
                      employment or contracted project work.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container margin-top-bg">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div>
                    <img
                      className="icon"
                      src={questionImage}
                      alt=""
                    />
                    <div className="title">
                      Handling Data Subject Rights Requests
                    </div>
                    <div className="section-text">
                      Teamble implemented compliance measures to make it easy to
                      handle requests such as deletion or update requests of your
                      personal data. To do so, simply email privacy@teamble.com with
                      your request.
                    </div>
                  </div>
                </div>
                <div
                  className="col-12 col-lg-6 sub-processors mt-64 mt-lg-0"
                >
                  <div>
                    <img
                      className="icon"
                      src={searchImage}
                      alt=""
                    />
                    <div className="title">
                      Third party audits
                    </div>
                    <div className="section-text">
                      Teamble audited all the services it uses from
                      <span style={{ fontWeight: 'bold' }}>subprocessors</span> to
                      ensure that they are either GDPR compliant or don’t gain
                      access to personal data. Our sub processors are: Amazon Web
                      Services Inc., cloud infrastructure service provider in the
                      US. Twilio Inc., cloud based communications platform in the
                      US, Zendesk Inc., cloud based customer service and support
                      provider in the US, Stripe Inc., cloud based payment
                      infrastructure provider in the US.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container margin-top-bg margin-bottom-sm">
              <div className="sub-header">
                Responsible Disclosure
              </div>
              <div className="md-layout">
                <div className="md-layout-item md-size-100 disclosure-text">
                  If you believe you’ve discovered a potential vulnerability, please
                  let us know by emailing us at security@teamble.com. We will
                  acknowledge your email within ten business days.
                </div>
                <div
                  className="md-layout-item md-size-100 disclosure-text margin-top-sm"
                >
                  Provide us with a reasonable amount of time to resolve the issue
                  before disclosing it to the public or a third party. We aim to
                  resolve critical issues within one month of disclosure.
                </div>
                <div
                  className="md-layout-item md-size-100 disclosure-text margin-top-sm margin-bottom-sm"
                >
                  Make a good faith effort to avoid violating privacy, destroying
                  data, or interrupting or degrading the Teamble service. Please
                  only interact with accounts you own or for which you have explicit
                  permission from the account holder.
                </div>
              </div>
            </div>
            <div className="container margin-top-sm margin-bottom-bg">
              <div className="title-left">
                Exclusions
              </div>
              <div className="md-layout w-100">
                <p className="md-layout-item md-size-100 disclosure-text">
                  While researching, we’d like you to refrain from:
                </p>
                <ul className="md-layout-item md-size-100 disclosure-text">
                  <li>Distributed Denial of Service (DDoS)</li>
                  <li>Spamming</li>
                  <li>
                    Social engineering or phishing of Teamble employees or
                    contractors
                  </li>
                  <li>
                    Any attacks against Teamble’s physical property or data centers
                  </li>
                </ul>
              </div>
            </div>
            <div className="container margin-bottom-bg margin-bottom-bg">
              <div className="title-question">
                Security questions or issues?
              </div>
              <div className="md-layout margin-bottom-bg">
                <p className="md-layout-item md-size-100 contact-text">
                  Please contact us at <strong>security@teamble.com</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Layout>
  );

};

export default SecurityFeatures;
